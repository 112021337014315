<template>
    <v-container class="white-bg mt-48px ct-mw">
      <v-row justify="center" class="text-center">
        <h4 class="navy-text">CORE ASSESSMENT</h4>
      </v-row>
      <v-row justify="center" class="text-center">
        <span class="base-text">แบบประเมินเพื่อค้นหาศักยภาพของตัวคุณ</span>
      </v-row>
      <v-divider></v-divider>
      <v-row justify="center" class="pb-4 pt-6">
          <v-img
            :src="require('../../assets/image/core_all.jpg')"
          ></v-img>
      </v-row>
        <v-row justify="center" class=" py-sm-2">
          <v-col class="" cols="12" xl="10">
            <v-row justify="center" class="text-center">
              <span class="text-sur base-text">
                ประกอบด้วยคำถาม 40 ข้อ
                ที่จะช่วยประเมินความเป็นตัวคุณ
                โดยเฉพาะความถนัดและมุมมองต่อโลกและชีวิตในด้านต่างๆ
                เพื่อผลลัพธ์ที่จะช่วยให้คุณได้เข้าใจศักยภาพของตัวเอง
                ขอให้คุณเลือกคำตอบที่ใกล้เคียงกับความรู้สึกของตัวคุณเองมากที่สุด
                ถึงแม้จะไม่ตรงกับตัวคุณทั้งหมด
                แต่ก็ขอให้เลือกคำตอบที่มีแนวโน้มใกล้เคียงกับตัวคุณมากกว่า<br/>
              </span>
            </v-row>

          </v-col>
        </v-row>
        <v-row justify="center">
          <v-btn color="error" to="survey/question">
            <span class="white-text">start</span>
          </v-btn>
      </v-row>
    </v-container>

</template>

<script>
export default {
  name: 'survey',
  data() {
    return {
      images: [
        require('../../assets/image/character-o.jpg'),
        require('../../assets/image/character-c.jpg'),
        require('../../assets/image/character-e.jpg'),
        require('../../assets/image/character-a.jpg'),
        require('../../assets/image/character-n.jpg'),
      ],
    };
  },
  methods: {

  },
};
</script>

<style lang="sass" scoped>
.text-sur
  font-size: 1.5rem
  line-height: 2.5rem
  @media screen and (max-width: 770px)
    font-size: 1rem
    line-height: 2rem
.img-sur
  background-color: ''
</style>
